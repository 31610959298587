import { Box, Typography, useTheme, TablePagination } from "@mui/material";
import { tokens } from "../../theme";

import {
  SwapHorizontalCircle,
  TrendingUp,
  TrendingDown,
  TrendingFlat,
  AttachMoney,
} from "@mui/icons-material";

import StatBox from "../../components/StatBox";

import Team from "../team";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  CartesianGrid,
} from "recharts";
import { PieChart, Pie, Cell } from "recharts";
import { useEffect, useState } from "react";

const Dashboard = () => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const colors = tokens(theme.palette.mode);
  const [trades, setTrades] = useState([]);
  const [assetFilter, setAssetFilter] = useState(null || "ALL");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  let cumulativePnl = 0;
  const datas = trades.map((trade) => {
    cumulativePnl += parseFloat(trade.closed_pnl);
    return {
      created_at: parseInt(trade.created_at),
      pnl: cumulativePnl,
    };
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const slicedData = trades.slice(startIndex, endIndex);
  useEffect(() => {
    fetch("https://trading.edamame-sushi.ch/api.php")
      .then((response) => response.json())
      .then((data) => setTrades(data));
  }, []);
  const filteredTrades = assetFilter
    ? trades.filter(
        (trade) => assetFilter === "ALL" || trade.symbol === assetFilter
      )
    : trades;
  const totalTrades = filteredTrades.length;
  const winningTrades = filteredTrades.filter(
    (trade) => trade.closed_pnl > 0
  ).length;
  const losingTrades = filteredTrades.filter(
    (trade) => trade.closed_pnl < 0
  ).length;
  const winLossRatio = winningTrades / losingTrades;
  const totalPnl = filteredTrades.reduce(
    (sum, trade) => sum + parseFloat(trade.closed_pnl),
    0
  );

  const pnlPercent = ((totalPnl / 1000) * 100).toFixed(2);

  const data = [
    { name: "Winning Trades", value: winningTrades },
    { name: "Losing Trades", value: losingTrades },
  ];
  const data1 = [{ name: "Total Trades", value: totalTrades }];

  const data2 = [{ name: "Win/Loss Ratio", value: winLossRatio }];
  const COLORS = ["#00c853", "#ff1744", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const handleChange = (e) => {
    setAssetFilter(e.target.value);
  };
  const formatDate = (value) => new Date(value * 1000).toLocaleDateString();
  const pnlValues = trades.map((trade) => parseFloat(trade.closed_pnl));
  const minPnl = Math.min(...pnlValues);
  const maxPnl = Math.max(...pnlValues);
  return (
    <>
      <Box m="20px">
        {/* HEADER */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        ></Box>

        {/* GRID & CHARTS */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={totalTrades}
              subtitle="Total Trades"
              progress={totalTrades / 80}
              icon={
                <SwapHorizontalCircle
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={winningTrades}
              subtitle="Winning Trades"
              progress={winningTrades / 50}
              icon={
                <TrendingUp
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={losingTrades}
              subtitle="Losing Trades"
              progress={losingTrades / 20}
              icon={
                <TrendingDown
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={winLossRatio}
              subtitle="Win/Loss Ratio"
              progress={winLossRatio / 10}
              icon={
                <TrendingFlat
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>

          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={totalPnl}
              subtitle="Total PnL"
              progress={winLossRatio / 10}
              icon={
                <SwapHorizontalCircle
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={pnlPercent}
              subtitle="PnL"
              progress={winLossRatio / 10}
              icon={
                <SwapHorizontalCircle
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={1000}
              subtitle="Starting Money"
              progress={winLossRatio / 10}
              icon={
                <AttachMoney
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
          {/* ROW 2 */}
          <Box
            gridColumn="span 12"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
          >
            <Box height="250px" m="20px 0 0 0">
              <ResponsiveContainer width="100%" height={250}>
                <AreaChart
                  width="100%"
                  height="100%"
                  data={datas}
                  syncId="anyId"
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <XAxis dataKey="created_at" tickFormatter={formatDate} />
                  <YAxis
                    domain={[minPnl, maxPnl]}
                    tickLine={false}
                    axisLine={false}
                    tick={{ fill: "grey", fontSize: 10 }}
                    tickFormatter={(value) => `$${value.toFixed(2)}`}
                  />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="pnl"
                    stroke="#82ca9d"
                    fill="#82ca9d"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </Box>

          {/* ROW 3 */}
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            p="30px"
          >
            <Typography variant="h5" fontWeight="600">
              Winning/Losing Trade
            </Typography>

            <ResponsiveContainer width="100%">
              <PieChart width="100%" height="100%">
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Box>
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            p="30px"
          >
            <Typography variant="h5" fontWeight="600">
              Total Trades
            </Typography>

            <ResponsiveContainer width="100%">
              <PieChart width="100%" height="100%">
                <Pie
                  dataKey="value"
                  isAnimationActive={false}
                  data={data1}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                />
                {data.map((entry, index) => (
                  <Cell
                    key={index}
                    fill={entry.value > 0 ? "#00C853" : "#FF1744"}
                  />
                ))}
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Box>
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            p="30px"
          >
            <Typography variant="h5" fontWeight="600">
              Win/Los Ratio
            </Typography>

            <ResponsiveContainer width="100%">
              <PieChart width="100%" height="100%">
                <Pie
                  dataKey="value"
                  isAnimationActive={false}
                  data={data2}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                />
                {data.map((entry, index) => (
                  <Cell
                    key={index}
                    fill={entry.value > 0 ? "#00C853" : "#FF1744"}
                  />
                ))}
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </Box>
      <Team
        trades={slicedData}
        handleChange={handleChange}
        asset={assetFilter}
      />
      <div>
        {/* Render your table here with the slicedTrades data */}
        <TablePagination
          component="div"
          count={trades.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default Dashboard;
