import { Box } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Team = ({ trades, handleChange, asset }) => {
  const uniqueSymbols = [...new Set(trades.map((asset) => asset.symbol))];
  return (
    <Box m="20px">
      <Box sx={{ minWidth: 200, mb: 3 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="demo-simple-select-label">Asset</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={asset}
            placeholder="Assets"
            label="Asset"
            onChange={handleChange}
            style={{ backgroundColor: "#1f2a40" }}
          >
            <MenuItem value="ALL">ALL</MenuItem>
            {uniqueSymbols.map((symbol) => (
              <MenuItem key={symbol} value={symbol}>
                {symbol}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper} sx={{ backgroundColor: "#1f2a40" }}>
        <Table
          sx={{ minWidth: 650, backgroundColor: "#1f2a40" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow sx={{ color: "#000" }}>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Symbol</TableCell>
              <TableCell align="right">Side</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Order Price Entry</TableCell>
              <TableCell align="right">Order Price Exit</TableCell>
              <TableCell align="right">Profit/Loss</TableCell>
              <TableCell align="right">Leverage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trades
              ?.sort((a, b) => b.created_at - a.created_at)
              .filter((trade) => asset === "ALL" || trade.symbol === asset)
              .map((trade) => (
                <TableRow
                  key={trade.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="right">
                    {new Date(
                      parseInt(trade.created_at) * 1000
                    ).toLocaleString()}
                  </TableCell>
                  <TableCell align="right">{trade.symbol}</TableCell>
                  <TableCell align="right">{trade.side}</TableCell>
                  <TableCell align="right">{trade.qty}</TableCell>
                  <TableCell align="right">{trade.order_price}</TableCell>
                  <TableCell align="right">{trade.avg_exit_price}</TableCell>
                  <TableCell align="right">
                    {(typeof trade.closed_pnl === "number" &&
                    trade.closed_pnl >= 0
                      ? "+"
                      : "") + parseFloat(trade.closed_pnl).toFixed(2)}
                  </TableCell>
                  <TableCell align="right">{trade.leverage}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Team;
